import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Subscription } from '../models/user.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/response.model';
import { AppConstants } from '../constants';
import { PaymentToken } from '../models/payment.model';
import { Transaction } from '../models/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  _subscriptionEndpoint = `${environment.apiBaseUrl}/subscription/v1/`;

  constructor(private _http: HttpClient) { }

  setSelectedCar(selectedCarInfo: any) {
    localStorage.setItem(AppConstants.SELECTED_CAR_KEY, JSON.stringify(selectedCarInfo));
  }

  getSelectedCar(): any | null {
    const selectedCar = localStorage.getItem(AppConstants.SELECTED_CAR_KEY);
    return selectedCar ? JSON.parse(selectedCar) : null;
  }

  removeSelectedCar() {
    localStorage.removeItem(AppConstants.SELECTED_CAR_KEY);
  }

  getSubscription(): Observable<any> {
    return this._http
      .get<ApiResponse<any>>(this._subscriptionEndpoint + 'subscription')
      .pipe(map((res) => res.data));
  }

  getSubscriptionById(subscriptionId: string): Observable<Subscription> {
    return this._http
      .get<ApiResponse<any>>(this._subscriptionEndpoint + 'subscription/' + subscriptionId)
      .pipe(map((res) => res.data));
  }

  getActiveSubscription(): Observable<any> {
    return this._http
      .get<ApiResponse<any>>(this._subscriptionEndpoint + 'subscription/active')
      .pipe(map((res) => res.data));
  }

  createSubscription(params: any): Observable<any> {
    return this._http
      .post<ApiResponse<any>>(this._subscriptionEndpoint + 'subscription', params)
      .pipe(map((res) => res.data));
  }

  createCorporateSubscription(params: any): Observable<any> {
    return this._http
      .post<ApiResponse<any>>(this._subscriptionEndpoint + 'corporate-subscriptions', params)
      .pipe(map((res) => res.data));
  }

  getCorporateSubscriptionPreSale(subscriptionId: string): Observable<any> {
    return this._http
      .get<ApiResponse<any>>(`${this._subscriptionEndpoint}corporate-subscriptions/${subscriptionId}/pre-sale`)
      .pipe(map((res) => res.data));
  }

  preSaleCorporateSubscription(subscriptionId: string, params: any): Observable<any> {
    return this._http
      .post<ApiResponse<any>>(`${this._subscriptionEndpoint}corporate-subscriptions/${subscriptionId}/pre-sale`, params)
      .pipe(map((res) => res.data));
  }

  updatePreSaleCorporateSubscription(subscriptionId: string, params: any): Observable<any> {
    return this._http
      .patch<ApiResponse<any>>(`${this._subscriptionEndpoint}corporate-subscriptions/${subscriptionId}/pre-sale`, params)
      .pipe(map((res) => res.data));
  }

  createPreSaleQuotation(subscriptionId: string, params: any): Observable<any> {
    return this._http
      .post<ApiResponse<any>>(`${this._subscriptionEndpoint}corporate-subscriptions/${subscriptionId}/pre-sale-quotation`, params)
      .pipe(map((res) => res.data));
  }

  getSubscriptionJourney(subscriptionId: string): Observable<any> {
    return this._http
      .get<ApiResponse<any>>(`${this._subscriptionEndpoint}subscription/journey/${subscriptionId}/`)
      .pipe(map((res) => res.data));
  }

  changeSubscriptionCar(subscriptionId: string, params: any): Observable<any> {
    return this._http
      .patch<ApiResponse<any>>(`${this._subscriptionEndpoint}subscription/change-vehicle/${subscriptionId}`, params)
      .pipe(map((res) => res.data));
  }

  getTokenPayment(subscriptionId: string): Observable<PaymentToken> {
    return this._http.get<ApiResponse<any>>(`${this._subscriptionEndpoint}payment/token/${subscriptionId}`)
      .pipe(map((res) => res.data));
  }

  getSecurityPayment(subscriptionId: string): Observable<PaymentToken> {
    return this._http.get<ApiResponse<any>>(`${this._subscriptionEndpoint}payment/security/${subscriptionId}`)
      .pipe(map((res) => res.data));
  }

  getAdvanceFeePayment(subscriptionId: string): Observable<PaymentToken> {
    return this._http.get<ApiResponse<any>>(`${this._subscriptionEndpoint}payment/advance-fee/${subscriptionId}`)
      .pipe(map((res) => res.data));
  }

  getPaymentStatus(linkId: string): Observable<any> {
    return this._http.get<ApiResponse<any>>(`${this._subscriptionEndpoint}payment/verify/${linkId}`)
      .pipe(map((res) => res.data));
  }

  getTransactions(subscriptionId: string): Observable<Transaction[]> {
    return this._http
      .get<ApiResponse<any>>(`${this._subscriptionEndpoint}payments/${subscriptionId}`)
      .pipe(map((res) => res.data));
  }

  getDocuments(subscriptionId: string): Observable<any> {
    return this._http
      .get<ApiResponse<any>>(`${this._subscriptionEndpoint}subscription/documents/${subscriptionId}`)
      .pipe(map((res) => res.data));
  }

  setupAutodebit(subscriptionId: string): Observable<any> {
    return this._http.post<ApiResponse<any>>(`${this._subscriptionEndpoint}payment/auto-debit/${subscriptionId}`, {})
      .pipe(map((res) => res.data));
  }

}
